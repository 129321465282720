import React, { useEffect } from 'react';
import { navigate } from 'gatsby';
import { isAuthenticated } from 'utils/_auth.js';
import {
  PATH_GUEST,
  PATH_HOME,
  PATH_ORDER,
  PATH_PAYMENT_FAIL,
} from 'utils/constants/paths.js';
import TitleName from 'components/UI/Header/TitleName';

const ResultPage = () => {
  useEffect(() => {
    if (!isAuthenticated()) {
      navigate(PATH_GUEST, {
        replace: true,
      });
      return;
    }

    let currentUrl = typeof window !== 'undefined' ? window.location.href : '';
    // 用問號將URL分為基本路徑和查詢字串
    const [basePath, queryString] = currentUrl.split('?');
    // 解析查詢字串
    const query = {};
    if (queryString) {
      queryString.split('&').forEach((item) => {
        const [key, value] = item.split('=');
        query[key] = decodeURIComponent(value);
      });
    }

    const code = query['code'];
    const transNo = query['trans'];
    const reason = query['reason'];

    if (code && transNo && reason) {
      if (code === '200') {
        // ALVIN 2023/05/26 多帶參數判斷是否為購物車結帳結果頁
        navigate(`${PATH_ORDER}/${transNo}?from=result`, {
          replace: true,
        });
      } else {
        navigate(`${PATH_PAYMENT_FAIL}/${transNo}?reason=${reason}`, {
          replace: true,
        });
      }
    } else {
      navigate(PATH_HOME, {
        replace: true,
      });
    }
  }, []);

  return (
    <>
      <TitleName />
    </>
  );
};

export default ResultPage;
